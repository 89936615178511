import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IDefaultParams } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class DefaultParamsService {
  private $params: AsyncSubject<IDefaultParams> = new AsyncSubject<IDefaultParams>();
  private params$ = this.$params.asObservable();

  constructor(private apiService: ApiService) {
    this.apiService.userGroup.getDefaultParams().subscribe((params) => {
      this.$params.next(params);
      this.$params.complete();
    });
  }

  public get(): Observable<IDefaultParams> {
    return this.params$;
  }
}
