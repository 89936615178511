import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Domain from '../../domain';
import { ITools } from '../../domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  constructor(private http: HttpClient) {}

  private readonly admin_api_path = '/light/api/admin';

  public getAll(search?: string, page: number = 0, take: number = 10): Observable<ITools[]> {
    let q = `page=${page}&take=${take}`;
    if (search) {
      q += `&search=${search}`;
    }

    return this.http.get<Domain.ITools[]>(`${this.admin_api_path}/tools/all?${q}`);
  }

  public get(id: string): Observable<ITools> {
    return this.http.get<Domain.ITools>(`${this.admin_api_path}/tools/tool?id=${id}`);
  }
}
