import { HttpHeaders, HttpParams } from '@angular/common/http';

export class HeadersToParamsAdapter {
  params = new HttpParams();

  constructor(headers: HttpHeaders, arrayKeys: string[] = []) {
    this.buildParams(headers, arrayKeys);
  }

  buildParams(headers: HttpHeaders, arrayKeys: string[]) {
    headers.keys().forEach((key) => {
      const valueArray = headers.getAll(key);

      if (valueArray?.length > 1) {
        if (!arrayKeys.includes(key))
          throw new Error(
            `Header ${key} has multiple values, but it is not in the arrayKeys list, make sure it is a array adding it to the arrayKeys list`,
          );

        this.params = valueArray.reduce((params, value) => {
          return params.append(key, value);
        }, this.params);
      } else this.params = this.params.append(key, valueArray?.[0]);
    });
  }

  getParams() {
    return this.params;
  }
}
