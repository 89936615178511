import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { BrowserSessionIdStorageKey } from 'src/app/constants';
import * as Domain from '../../domain';
import { Group, ShareableObject } from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class UsergroupApiService {
  groupsCache: Group[] = [];

  constructor(private http: HttpClient) {}

  private readonly usergroup_api_path = '/light/api/User';
  private readonly group_api_path = '/light/api/group';

  listUsers(
    groupId?: string,
    limit: number = api_default_limit,
    page: number = api_default_page,
    isGlobal: boolean = false,
    status: string[] = [],
    useCache?: boolean,
    onlyPermittedRoles?: boolean,
  ): Observable<Domain.User[]> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit) });
    if (useCache) headers = headers.append('useCache', 'true');
    const permittedRoles = Object.values(Domain.RolePermission);
    if (
      !localStorage.getItem('usersCache') ||
      !JSON.parse(localStorage.getItem('usersCache')).lastUpdate ||
      new Date(new Date(JSON.parse(localStorage.getItem('usersCache')).lastUpdate).getTime() + 12 * 60 * 60 * 1000) <
        new Date()
    ) {
      this.http
        .get<Domain.RequestResponse>(`${this.usergroup_api_path}${isGlobal ? '/global' : ''}`, { headers: headers })
        .pipe(map((res) => res.data?.docs as Domain.User[]))
        .subscribe((data) => {
          localStorage.setItem(
            'usersCache',
            JSON.stringify({
              lastUpdate: new Date().getTime(),
              data: data,
            }),
          );
        });
    } else {
      this.getInactiveGroupsRedis().subscribe((groups) => {
        const inactiveGroupNames = groups.map((group) => group.Name);
        const usersCached = JSON.parse(localStorage.getItem('usersCache')).data.filter((user) => {
          const hasRoles = !isGlobal || user.Roles.some((role) => permittedRoles.includes(role));
          const hasStatus = !status?.length || status.includes(user.Status);
          const isGroupActive = !inactiveGroupNames.includes(user.GroupName);
          return hasRoles && hasStatus && isGroupActive;
        });

        return of(usersCached);
      });
    }

    if (groupId) {
      headers = headers.append('groupId', groupId);
    }
    if (isGlobal) headers = headers.append('isGlobal', 'true');
    if (status?.length) headers = headers.append('status', status);

    return this.http
      .get<Domain.RequestResponse>(`${this.usergroup_api_path}${isGlobal ? '/global' : ''}`, { headers: headers })
      .pipe(
        map((res) => res.data?.docs as Domain.User[]),
        map((users) => {
          if (onlyPermittedRoles) return users.filter((user) => this.hasPermission(user.Roles, permittedRoles));
          return users;
        }),
      );
  }

  hasPermission(userRoles: string[], permittedRoles: string[]): boolean {
    for (const role of userRoles) {
      if (permittedRoles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  listUsersAccess(
    security: Domain.SecurityType,
    sensitivity: Domain.SensitivityLevel,
    owner_id: string,
    situation_id?: string,
    outside_of_ya?: boolean,
  ): Observable<Domain.User[]> {
    let headers = new HttpHeaders({
      security: security,
      sensitivity: String(sensitivity),
      owner_id: owner_id,
      outsideya: outside_of_ya ? 'true' : '',
    });
    if (situation_id) {
      headers = headers.append('situation_id', situation_id);
    }
    return this.http
      .get<Domain.RequestResponse>(`${this.usergroup_api_path}/Access`, {
        headers: headers,
      })
      .pipe(map((res) => res.data as Domain.User[]));
  }

  getUser(id: string): Observable<Domain.User | null> {
    const headers = new HttpHeaders({ id: id, limit: String(api_default_limit), page: String(api_default_page) });
    return this.http.get<Domain.RequestResponse>(`${this.usergroup_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.User;
      }),
    );
  }

  getUsernamesList(userIds: string[]): Observable<any> {
    let params = new HttpParams();
    userIds.forEach((id) => {
      params = params.append('userIds', id);
    });
    return this.http.get<any>(`${this.usergroup_api_path}/usernamesById`, { params });
  }

  getCurrentIP(): Observable<any> {
    return this.http.get(`${this.usergroup_api_path}/currentUserIP`);
  }

  heartbit(app: 'HMI' | 'LIGHT'): Observable<any> {
    const headers = { [BrowserSessionIdStorageKey]: localStorage.getItem(BrowserSessionIdStorageKey) };
    return this.http.head(`${this.usergroup_api_path}/heartbit/${app}`, { headers });
  }

  getCurrentUser(cache: boolean = true): Observable<Domain.User> {
    let headers = new HttpHeaders({});
    if (!cache) headers = headers.append('Cache-Control', 'no-cache');
    return this.http.get<Domain.RequestResponse>(`${this.usergroup_api_path}/current?appType=hmi`, { headers }).pipe(
      map((res) => {
        return res.data as Domain.User;
      }),
    );
  }

  forceLogout(app: 'hmi' | 'light'): Observable<string> {
    return this.http.post<Domain.RequestResponse>(`${this.usergroup_api_path}/forceLogout`, { body: { app } }).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  updateVesselNameDisplay(id: string[], operation: any = 'add'): Observable<string[]> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.usergroup_api_path}/updateVesselNameDisplay`, { id, operation })
      .pipe(
        map((res) => {
          return res.data as string[];
        }),
      );
  }

  getMSAMapConfig(): Observable<Domain.MSAMapConfig> {
    return this.http.get<Domain.RequestResponse>(`${this.usergroup_api_path}/msaMapConfig`).pipe(
      map((res) => {
        return res.data as Domain.MSAMapConfig;
      }),
    );
  }

  getUserPermissions(): Observable<{
    UserPermissions: Domain.UserPermissions;
    AllPossiblesRolesNumbers: { [propName: string]: number };
  }> {
    return this.http.get<Domain.RequestResponse>(`${this.usergroup_api_path}/userPermissions`).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  updateUserPreferences(preferences: Domain.UserPreferences): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.usergroup_api_path}`, preferences)
      .pipe(map((res) => res.data as Domain.User));
  }

  async refreshSession(appType: string) {
    const res = <any>await this.http
      .post(`/light/api/refresh?appType=${appType}`, { useReferer: true })
      .toPromise()
      .catch((e) => console.error(e));

    if (res?.loginURL) {
      console.log(new Date().toDateString());
      window.location = res.loginURL;
    }
  }

  createDefaultParams(data: Domain.IDefaultParams) {
    return this.http.post<Domain.IDefaultParams>(`${this.usergroup_api_path}/defaultparams`, data);
  }

  getDefaultParams(): Observable<Domain.IDefaultParams> {
    return this.http.get(`${this.usergroup_api_path}/defaultparams`).pipe(map((res: any) => res.data[0]));
  }

  getLogoURL() {
    return this.http
      .get<Domain.RequestResponse>(`${this.usergroup_api_path}/logourl`)
      .pipe(map((res: any) => res.data as string));
  }

  getUserMapConfig(situationId: string, userId: string): Observable<Domain.UserMapConfig[]> {
    const params = new HttpParams()
      .append('SituationId', situationId)
      .append('UserId', userId)
      .append('AppType', 'HMI');

    return this.http
      .get(`${this.usergroup_api_path}MapConfig/`, { params: params })
      .pipe(map((res) => res as Domain.UserMapConfig[]));
  }

  createUserMapConfig(userMapConfig: Domain.UserMapConfig): Observable<Domain.UserMapConfig> {
    return this.http
      .post(`${this.usergroup_api_path}MapConfig/`, userMapConfig)
      .pipe(map((res) => res as Domain.UserMapConfig));
  }

  updateUserMapConfig(userMapConfig: Domain.UserMapConfig): Observable<Domain.UserMapConfig> {
    return this.http
      .put(`${this.usergroup_api_path}MapConfig/`, userMapConfig)
      .pipe(map((res) => res as Domain.UserMapConfig));
  }

  getBandwidthTestFile() {
    return this.http.get('./bandwidthTestFile.json', {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }

  listUserGroups(level?: string, groupId?: string, forceRequest?: boolean): Observable<Domain.Group[]> {
    let headers = new HttpHeaders();
    if (level) headers = headers.append('level', level);
    if (groupId) {
      headers = headers.append('groupId', groupId);
    }
    return this.http
      .get<Domain.RequestResponse>(`/light/api/group`, { headers: headers })
      .pipe(
        tap((res) => {
          this.groupsCache = res.data.docs as Domain.Group[];
        }),
      )
      .pipe(map((res) => res.data as Domain.Group[]));
  }

  listGroupStatus(level?: string, groupId?: string): Observable<Domain.Group[]> {
    let headers = new HttpHeaders();

    if (level) headers = headers.append('level', level);

    return this.http
      .get<Domain.RequestResponse>(`${this.group_api_path}/status`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Group[]));
  }

  getAvailableUsers(context: ShareableObject) {
    const headers = new HttpHeaders({ context: JSON.stringify({ ...context, Users: null }) });
    return this.http
      .get(`${this.usergroup_api_path}/availableUsers/`, { headers: headers })
      .pipe(map((res) => res as Domain.User[]));
  }

  getAvailableGroups(context: ShareableObject) {
    const headers = new HttpHeaders({ context: JSON.stringify(context) });
    return this.http
      .get(`${this.group_api_path}/availableGroups/`, { headers: headers })
      .pipe(map((res) => res as Domain.Group[]));
  }

  getUsersWithAccess(context: ShareableObject) {
    const headers = new HttpHeaders({ context: JSON.stringify(context) });
    return this.http
      .get(`${this.usergroup_api_path}/usersWithAccess/`, { headers: headers })
      .pipe(map((res) => res as Domain.User[]));
  }

  getGroupsWithAccess(context: ShareableObject) {
    const headers = new HttpHeaders({ context: JSON.stringify(context) });
    return this.http
      .get(`${this.group_api_path}/groupsWithAccess/`, { headers: headers })
      .pipe(map((res) => res as Domain.SharingGroup[]));
  }

  getInactiveGroupsRedis() {
    return this.http.get(`${this.group_api_path}/inactiveGroupsRedis`).pipe(map((res) => res as Domain.Group[]));
  }
}
